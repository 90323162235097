import styles from "./SliderModal.module.scss";

const SliderModal = () => {
  return (
    <div className={styles.wrapper}>
      <img src="/images/lines.png" alt="" />
      <div className="container">
        <p className={styles.title}>
          Конкурс актерского мастерства «Жаңы Каарман»
        </p>
        <p className={styles.o_desc}>
          Открытый актерский конкурс для профессионалов и энтузиастов с любой
          степенью подготовки без возрастных ограничений
        </p>
        <p className={styles.title + " " + styles.celi}>Цели и задачи конкурса</p>
        <div className={styles.r_block}>
          <div>
            <p>Развитие актерской профессии в Кыргызстане </p>
          </div>
          <div>
            <p>Открытие и поддержка талантливых актеров в Кыргызстане </p>
          </div>
          <div>
            <p>
              Стимулирование интереса к театральному и кинематографическому
              искусству{" "}
            </p>
          </div>
          <div>
            <p>
              Развитие профессиональной платформы для обмена опытом между
              новичками и профессионалами{" "}
            </p>
          </div>
        </div>
        <div className={styles.info_block}>
          <div>
            <img src="/images/chel.png" className={styles.chel} alt="" />
            <img src="/images/phim.png" className={styles.phim} alt="" />
          </div>
          <div>
            <p className={styles.c_title}>
              Конкурс посвящен 85-летию великого кыргызского актера Суймонкула
              Чокморова
            </p>
            <p className={styles.c_desc}>
              Сүймөнкул Чокморов (9/11/39 – 26/09/92) — советский кыргызский
              актер кино, художник. Народный артист СССР (1981). В кино пришёл в
              1967 году. В 1970-80-е годы был одним из самых узнаваемых актёров
              в советских среднеазиатских «истернах» на историко-революционные
              темы. Много снимался в экранизациях произведений кыргызского
              писателя Чингиза Айтматова. Как живописец великий актер писал
              пейзажи, натюрморты, портреты (около 400 работ). Основные
              живописные произведения хранятся в Кыргызском государственном
              музее изобразительных искусств им. Г. Айтиева В 1991 году за
              огромный вклад в дело развития кыргызского изобразительного
              искусства и киноискусства, высокопрофессиональное исполнение ролей
              в фильмах, снятых по произведениям Ч. Айтматова был удостоен
              международной премии клуба Ч. Айтматова. Фильм «Лютый», с участием
              С.Чокморова стал обладателем почётного диплома Кинофестиваля в
              Локарно и был номинирован от СССР на премию «Оскар». В канун 2000
              года Суймонкул Чокморов признан лучшим актёром Кыргызстана XX века
            </p>
          </div>
        </div>
        <div className={styles.present_block}>
          <p className={styles.title  + " " + styles.celi}>Награды финалистам</p>
          <p className={styles.p_title}>
            Предусмотрены денежные призы для всех финалистов по 50.000 сом, а
            также бесплатный интенсив актерского мастерства.
          </p>
          <div>
            <div>
            <button>Купить билет</button>

            </div>
            <div>
              <p>
                Начало приема конкурсных работ <span>15 июля 2024</span>
              </p>
              <p>
                Последний срок подачи работ <span>5 сентября 2024</span>
              </p>
              <p>
                Объявление полуфиналистов конкурса <span>20 сентября 2024</span>
              </p>
              <p>
                Объявление и награждение <span>4 октября 2024</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.rules}>
          <p className={styles.title  + " " + styles.celi}>Основные правила участия</p>
          <div>
            <div>
              <p className={styles.r_title}>Тематика конкурсных работ</p>
              <p className={styles.desc}>
                Адаптация существующего кино- или литературного произведения.
              </p>
              <p className={styles.desc}>Создание оригинального контента.</p>
            </div>
            <div>
              <p className={styles.r_title}>Язык конкурсных работ</p>
              <p className={styles.desc}>Кыргызкий или Русский</p>
            </div>
            <div>
              <p className={styles.r_title}>Формат подачи работ </p>
              <p className={styles.desc}>
                Видеоролик до 5 минут. Без ограничений по формату
              </p>
              <p className={styles.desc}>Короткометражный фильм.</p>
              <p className={styles.desc}>Видеоклип.</p>
              <p className={styles.desc}>
                Видеозапись фрагмента моноспектакля или иного перформанса.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderModal;
