import scss from "./Komanda.module.scss";
import "./kom.css";

function Komanda() {
  return (
    <div className={scss.Komanda_w}>
        <p className={scss.dd}>Сеть кинотеатров «Синематика» представляет проект QARASH LAB Лаборатория кино</p>
        <h2>Команда проекта</h2>

      <div className={scss.container}>
        <div className={scss.box}>
        </div>
        <div className={scss.box}>
          <img src="/images/s1.jpeg" />
          <div>
            <p className={scss.title}>Алиса Логутенко</p>
            <p className={scss.desc}>Креативный Директор</p>
          </div>
        </div>
        <div className={scss.box}>
          <img src="/images/s2.jpeg" />
          <div>
            <p className={scss.title}>Айжан Иманкулова</p>
            <p className={scss.desc}>Исполнительный Директор</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Komanda;
